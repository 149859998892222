@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply bg-gray-50
}

.react-datepicker .day-sun {
    @apply text-red-500
}
.react-datepicker .day-sat {
    @apply text-blue-500
}
.react-datepicker__day-name:nth-child(1) {
    @apply text-red-500
}
.react-datepicker__day-name:nth-child(7) {
    @apply text-blue-500
}